import React, { useEffect, useMemo } from 'react';

import type { EWKT, GraphQLInfoState } from '@tmapy/config';
import { Dispatch } from '@tmapy/redux';
import { QUERY_STATE } from '@tmapy/utils';
import { EWKTRectangularBuffer } from '@tmapy/mapcore';
import type { RouteContext } from '@tmapy/types';

import type { DecorationContext } from 'lib/graphql/visitors/decorateDocument';
import { useGraphQLDocument } from 'lib/graphql/useGraphQLDocument';

import { setInfoServiceData } from '../actions';

const INFO_DECORATION_CONTEXT: DecorationContext = {
  isPage: false,
};

type RenderInfoQueryProps = {
  routeContext?: RouteContext;
  geom: EWKT;
  distanceInMeters: number;
  config: GraphQLInfoState;
  isVisible: boolean;
  dispatch: Dispatch;
};

export const RenderInfoQuery: React.FC<RenderInfoQueryProps> = ({
  routeContext,
  geom,
  distanceInMeters,
  config,
  isVisible,
  dispatch,
}) => {
  const variables = useMemo(
    () => ({
      geom: EWKTRectangularBuffer(geom, distanceInMeters),
    }),
    [geom, distanceInMeters],
  );

  const graphQuery = useGraphQLDocument(
    config.query,
    variables,
    routeContext,
    INFO_DECORATION_CONTEXT,
  );

  const { loading, error, errors, data, QueryComponent } = graphQuery;

  useEffect(() => {
    if (loading) {
      dispatch(
        setInfoServiceData(config.id, {
          queryState: QUERY_STATE.WAITING,
        }),
      );
    } else if (error) {
      dispatch(
        setInfoServiceData(config.id, {
          queryState: QUERY_STATE.FAIL,
        }),
      );
    } else {
      dispatch(
        setInfoServiceData(config.id, {
          queryState: QUERY_STATE.SUCCESS,
        }),
      );
    }
  }, [dispatch, loading, error, config.id]);

  if (!isVisible) return null;

  const selector = (QueryComponent as any).selector;
  const selectedData = selector ? selector(data, variables) : data;

  return (
    <QueryComponent
      data={selectedData}
      errors={errors ?? []}
      path={[]}
      loading={loading}
      variables={variables}
    />
  );
};
