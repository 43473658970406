import type { DirectiveNode, DocumentNode, FieldNode, GraphQLField, GraphQLSchema } from 'graphql';
import { getNamedType } from 'graphql';

import type { RouteContext } from '@tmapy/types';

import '../graphqlNodes';
import { isConnectionType } from '../utils/getConnectionInfo';
import { getDirectives } from '../utils/getDirectives';
import type { DataComponent } from '../types';

import { createDetailFormComponent } from './detailComponents/createDetailFormComponent';
import { createHistoryTableComponent } from './tableComponents/createHistoryTableComponent';
import { createTableComponent } from './tableComponents/createTableComponent';

export function createQueryComponent(
  graphqlField: GraphQLField<any, any, any>,
  directivesFromSchema: readonly DirectiveNode[],
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
  routeContext: RouteContext,
): DataComponent {
  const graphqlType = graphqlField.type;
  const namedType = getNamedType(graphqlType);

  const directives = getDirectives([...directivesFromSchema, ...(field.directives ?? [])]);

  if (directives.historyTable) {
    return createHistoryTableComponent(graphqlField, field, document, schema, routeContext);
  }

  if (isConnectionType(namedType)) {
    return createTableComponent(graphqlField, field, document, schema, routeContext);
  }

  return createDetailFormComponent(graphqlField, field, document, schema, routeContext);
}
