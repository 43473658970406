import type { GraphQLSchema } from 'graphql';
import { visit } from 'graphql';

import { gql } from '@apollo/client';

import type {
  QueryRouteConfigTWC,
  RouteConfigTWC,
  RouteComponentType,
  RouteStateTWC,
} from '@tmapy/types';
import type { HasPermission, TWC, WFSInfoState } from '@tmapy/config';
import { isVoidMapConfig } from '@tmapy/config';
import { LocaleMessages } from '@tmapy/intl';

import {
  createDocumentFromSchema,
  prepareServerDocument,
  getDirectives,
  hasOperation,
} from 'lib/graphql';

import { getRootRoute } from './getRootRoute';

const isQueryRouteConfigTWC = (route: RouteConfigTWC): route is QueryRouteConfigTWC => {
  return !!(
    (route.component === 'RouterQuery' || !route.component) &&
    route.query &&
    typeof route?.query === 'string'
  );
};

const processGraphQLRouteConfig = (
  route: QueryRouteConfigTWC,
  isCorrectMapConfig: boolean,
  schema: GraphQLSchema,
  hasPermission: HasPermission,
): RouteStateTWC => {
  const rawDocument = gql(route.query);
  const auth = route.auth ?? false;
  const hasBasePermission = hasOperation(route.query, schema, hasPermission);

  const childrenRouteIds: string[] = [];
  visit(rawDocument, {
    Field: (field) => {
      const directives = getDirectives(field.directives);
      if (directives.create) {
        childrenRouteIds.push(directives.create.route);
      }
    },
  });

  let showMap = !!route.defaultSize;
  visit(prepareServerDocument(rawDocument, schema), {
    Field: (field) => {
      if (field.namedType?.name === 'EWKT') {
        showMap = isCorrectMapConfig;
      }
    },
    VariableDefinition: (node) => {
      const type = node.type.kind === 'NonNullType' ? node.type.type : node.type;
      if (type.kind === 'NamedType' && type.name.value === 'EWKT') {
        showMap = isCorrectMapConfig;
      }
    },
  });

  return { ...route, showMap, hasBasePermission, childrenRouteIds, auth, component: 'RouterQuery' };
};

const mapRoutes: RouteComponentType[] = ['Panorama', 'MapInfo', 'Map'];

const excludedRouteComponents: RouteComponentType[] = [
  'IDE',
  'Panorama',
  'MapInfo',
  'Map',
  'UserProfile',
  'About',
];
const notExcludedRouteIds = ['dmx.info', 'dmx.map'];

const processMapRouteConfig = (route: RouteConfigTWC, showMap: boolean): RouteStateTWC => {
  const component = route.component;
  const auth = route.auth ?? false;
  const hasBasePermission = true;
  if (component && mapRoutes.includes(component)) {
    return { ...route, component, showMap, auth, hasBasePermission };
  }
  return { ...route, component: component ?? 'NotFound', showMap: false, auth, hasBasePermission };
};

export const processRoutesConfig = (
  config: TWC,
  schema: GraphQLSchema,
  hasPermission: HasPermission,
  lang: string,
  messages: LocaleMessages,
): RouteStateTWC[] => {
  const showMap = !isVoidMapConfig(config.map);

  const basename = typeof config.basename === 'object' ? config.basename[lang] : config.basename;
  const routes: RouteStateTWC[] = (config.routes ?? [])
    .map((route) => {
      const path = typeof route.path === 'object' ? route.path[lang] : route.path;

      if (route.component === undefined && !route.query && path !== '') {
        route.query = createDocumentFromSchema({ route, path, basename, schema });
      }
      return route;
    })
    .filter((route) => {
      const path = typeof route.path === 'object' ? route.path[lang] : route.path;

      if (path === '') {
        console.error(
          `[config.routes]: Config includes route with empty path (route id: "${route.id}").`,
        );
        return false;
      } else if (route.component === undefined) {
        return true;
      } else if (
        !excludedRouteComponents.includes(route.component) ||
        notExcludedRouteIds.includes(route.id)
      ) {
        return true;
      } else {
        console.error(
          `[config.routes]: Config includes unsupported route component: "${route.component}".`,
        );
        return false;
      }
    })
    .map((route) =>
      isQueryRouteConfigTWC(route)
        ? processGraphQLRouteConfig(route, showMap, schema, hasPermission)
        : processMapRouteConfig(route, showMap),
    );

  const isAboutRouteConfigured = !!(
    messages[`${config.appId}.about.content.html`] || messages['about.content.html']
  );
  const rootRoute = getRootRoute(routes, lang);
  const auth = !!(config.map?.requireAuth ?? (rootRoute ?? routes[0])?.auth);
  const hasBasePermission = true;

  if (rootRoute) {
    // TWC router chape jako root path prazdny retezec.
    // Pouze lomitku "/" nerozumi, proto..
    // ..prevedeni root route path "/" na "".
    rootRoute.path =
      typeof rootRoute.path === 'object'
        ? Object.fromEntries(
            Object.entries(rootRoute.path).map(([key]) => {
              return [key, ''];
            }),
          )
        : '';
  }

  // MAP
  if (
    !routes.find((route) => route.component === 'Map') &&
    showMap &&
    !(rootRoute && routes.every((route) => route.showMap))
  ) {
    routes.push({
      id: 'map',
      auth,
      hasBasePermission,
      path: {
        cs: rootRoute || isAboutRouteConfigured ? '/mapa' : '',
        en: rootRoute || isAboutRouteConfigured ? '/map' : '',
      },
      component: 'Map',
      showMap: true,
    });
  }
  const isMapRoute = routes.some((route) => route.component === 'Map');

  // PANORAMA
  const existPanoramaInfoService = !!(
    config.info?.services.find((service) => service.type === 'WFS') as WFSInfoState
  )?.properties?.fields?.find((field) => field.type === 'panorama');
  if (isMapRoute && existPanoramaInfoService) {
    routes.push({
      id: 'panorama',
      auth,
      hasBasePermission,
      path: '/panorama',
      component: 'Panorama',
      defaultSize: 'large',
      showMap: true,
    });
  }

  // INFO
  if (
    isMapRoute &&
    !routes.find((route) => route.component === 'MapInfo') &&
    (config.info?.services.length || config.info?.coordsInfo)
  ) {
    routes.push({
      id: 'info',
      auth,
      hasBasePermission,
      path: {
        cs: '/mapa/:geom',
        en: '/map/:geom',
      },
      component: 'MapInfo',
      defaultSize: 'small',
      showMap: true,
    });
  }

  // USER PROFILE
  routes.push({
    id: 'user-profile',
    auth: true,
    hasBasePermission,
    path: {
      cs: '/profil-uzivatele',
      en: '/user-profile',
    },
    component: 'UserProfile',
    showMap: false,
  });

  // ABOUT
  if (isAboutRouteConfigured && !rootRoute) {
    routes.push({
      id: 'about',
      auth,
      hasBasePermission,
      path: {
        cs: '',
        en: '',
      },
      component: 'About',
      showMap,
    });
  }

  // IDE
  routes.push({
    id: 'IDE',
    path: '/IDE',
    component: 'IDE',
    auth: false,
    hasBasePermission: true,
    showMap: false,
  });

  return routes;
};
