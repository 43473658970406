import { useMessage } from '@tmapy/intl';
import { useLink, useLocation } from '@tmapy/router';
import { SecondaryBtn } from '@tmapy/style-guide';

import { msg } from '../messages';

type CloseButtonProps = {
  parentRouteId: string;
};

export const CloseButton = ({ parentRouteId }: CloseButtonProps) => {
  const formatMessage = useMessage();
  const closeMessage = formatMessage.fallback([msg.close]);

  const location = useLocation();
  const link = useLink(parentRouteId, location.params);
  if (!link.href) {
    console.error('Not all attributes are available to display the close button.');
    return null;
  }

  return <SecondaryBtn {...link}>{closeMessage}</SecondaryBtn>;
};
