import { defineMessages } from 'react-intl.macro';

import React, { Fragment, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DangerAlert, WaitingAlert } from '@tmapy/style-guide';
import { Dispatch } from '@tmapy/redux';
import type {
  InfoServiceState,
  InfoServicesState,
  LayerState,
  EWKT,
  InfoDataState,
} from '@tmapy/config';
import { LAYER_IDS } from '@tmapy/config';
import { actionSetLayerFeatures, EWKTToGeoJSONObject } from '@tmapy/mapcore';
import type { RouteContext } from '@tmapy/types';
import { QUERY_STATE } from '@tmapy/utils';

import { Section } from '../../graphql/components/Section';
import { msg as globalMsg } from '../../graphql/messages';
import { RenderInfoQuery } from './RenderInfoQuery';
import { InfoAtPoint } from './InfoAtPoint';
import { InfoBomby } from './InfoBomby';

const msg = defineMessages({
  infoAlertError: {
    id: 'sys.page.info.alert.error',
    defaultMessage: 'Některé informace se nepodařilo načíst. Zkuste to prosím později.',
  },
});

export type InfoProps = {
  dispatch: Dispatch;
  geom: EWKT;
  distanceInMeters: number;
  services: InfoServicesState;
  layers: LayerState[];
  data: Record<string, InfoDataState>;
  routeContext?: RouteContext;
};

const EMPTY_DATA: InfoDataState = {
  queryState: QUERY_STATE.EMPTY,
};

export const Info: React.FC<InfoProps> = React.memo(
  ({ routeContext, services, layers, dispatch, geom, distanceInMeters, data }) => {
    const visibleLayers = useMemo(
      () => layers.filter((layer) => layer.layerOptions.visible).map((layer) => layer.id),
      [layers],
    );

    useEffect(() => {
      dispatch(actionSetLayerFeatures(LAYER_IDS.INFO, [EWKTToGeoJSONObject(geom)]));
      return () => {
        dispatch(actionSetLayerFeatures(LAYER_IDS.INFO, []));
        dispatch(actionSetLayerFeatures(LAYER_IDS.INFO_RESULTS, []));
      };
    }, [geom, dispatch]);

    let isVisible = true;
    let isError = false;

    return (
      <>
        {services
          .filter(
            (infoConfig: InfoServiceState) =>
              !infoConfig.onlyIfLayersVisible.length ||
              infoConfig.onlyIfLayersVisible.some((layerId) => visibleLayers.includes(layerId)),
          )
          .map((infoConfig: InfoServiceState) => (
            <Fragment key={infoConfig.id}>
              {(() => {
                switch (infoConfig.type) {
                  case 'WFS':
                  case 'WMS':
                  case 'ArcGISRest': {
                    isVisible =
                      isVisible &&
                      !![QUERY_STATE.SUCCESS, QUERY_STATE.FAIL].includes(
                        data[infoConfig.id]?.queryState,
                      );
                    isError = isError || data[infoConfig.id]?.queryState === QUERY_STATE.FAIL;
                    const infoBomby = [
                      'bomby_0_0p_info',
                      'bomby_1_1p_info',
                      'bomby_2_2p_info',
                      'bomby_3_3p_info',
                      'bomby_4_4p_info',
                      'bomby_5_5p_info',
                    ];
                    if (infoBomby.includes(infoConfig.id)) {
                      return (
                        <InfoBomby
                          geom={geom}
                          distanceInMeters={distanceInMeters}
                          config={infoConfig}
                          data={data[infoConfig.id] ?? EMPTY_DATA}
                          dispatch={dispatch}
                          isVisible={
                            isVisible && data[infoConfig.id]?.queryState === QUERY_STATE.SUCCESS
                          }
                        />
                      );
                    }
                    return (
                      <InfoAtPoint
                        geom={geom}
                        distanceInMeters={distanceInMeters}
                        config={infoConfig}
                        data={data[infoConfig.id] ?? EMPTY_DATA}
                        dispatch={dispatch}
                        isVisible={
                          isVisible && data[infoConfig.id]?.queryState === QUERY_STATE.SUCCESS
                        }
                      />
                    );
                  }
                  case 'HTML': {
                    return isVisible ? (
                      <Section>
                        <div
                          className='sg-u-box sg-a-p-2'
                          dangerouslySetInnerHTML={{ __html: infoConfig.content }}
                        />
                      </Section>
                    ) : null;
                  }
                  case 'GraphQL': {
                    isVisible =
                      isVisible &&
                      !![QUERY_STATE.SUCCESS, QUERY_STATE.FAIL].includes(
                        data[infoConfig.id]?.queryState,
                      );
                    isError = isError || data[infoConfig.id]?.queryState === QUERY_STATE.FAIL;
                    return (
                      <RenderInfoQuery
                        routeContext={routeContext}
                        geom={geom}
                        distanceInMeters={distanceInMeters}
                        config={infoConfig}
                        dispatch={dispatch}
                        isVisible={isVisible}
                      />
                    );
                  }
                }
              })()}
            </Fragment>
          ))}

        {!isVisible && (
          <div className='sg-a-p-s sg-a-l-0 sg-a-mt-2'>
            <WaitingAlert>
              <FormattedMessage {...globalMsg.waiting} />
            </WaitingAlert>
          </div>
        )}
        {isVisible && isError && (
          <div className='sg-a-p-s sg-a-l-0 sg-a-mt-2'>
            <DangerAlert>
              <FormattedMessage {...msg.infoAlertError} />
            </DangerAlert>
          </div>
        )}
      </>
    );
  },
);
