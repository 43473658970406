import { defineMessages } from 'react-intl.macro';

import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '@tmapy/redux';
import { EWKT, HASH_PARAM } from '@tmapy/config';
import type { RouteStateTWC } from '@tmapy/types';

import { Info } from 'lib/info';
import { PageTitle, DataLayoutSpacing } from 'lib/graphql';

const msg = defineMessages({
  pageTitle: { id: 'sys.page.info.title', defaultMessage: 'Identifikace místa v mapě' },
});

type MapInfoProps = {
  route: RouteStateTWC;
};

export const MapInfo: React.FC<MapInfoProps> = ({ route }) => {
  const dispatch = useDispatch();
  const ewkt = useSelector((state) => state.router.params.geom) as EWKT;
  const distanceInMeters = parseFloat(
    useSelector((state) => state.router.hashParams[HASH_PARAM.DISTANCE_IN_METERS]) ?? '',
  );

  const resolution = useSelector((state) => state.mapCore.view.resolution);

  const { services, data } = useSelector((state) => state.info);
  const { layers } = useSelector((state) => state.mapCore);

  return (
    <DataLayoutSpacing>
      <div className='tw-mapInfo sg-a-p-s sg-a-l-0 sg-a-mb-2'>
        <PageTitle>
          <FormattedMessage {...msg.pageTitle} />
        </PageTitle>
      </div>
      {ewkt && resolution && (
        <Info
          routeContext={route.context}
          geom={ewkt}
          distanceInMeters={distanceInMeters}
          data={data}
          services={services}
          layers={layers}
          dispatch={dispatch}
        />
      )}
    </DataLayoutSpacing>
  );
};
