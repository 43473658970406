import React from 'react';
import { DocumentNode } from 'graphql';

import { DangerAlert } from '@tmapy/style-guide';
import type { RouteContext } from '@tmapy/types';

import type { OperationContext } from '../useGraphQLDocument';
import { useGraphQLDocument } from '../useGraphQLDocument';
import { Variables } from '../types';
import { GraphQLQueryError, QueryError } from './QueryError';
import { DataLayoutSpacing } from './DataLayoutSpacing';

type RenderQueryProps = OperationContext & {
  query: string | DocumentNode;
  variables: Variables;
  routeContext?: RouteContext;
};

export const RenderQuery: React.FC<RenderQueryProps> = ({
  query,
  variables,
  routeContext,
  ...operationContext
}) => {
  const graphQuery = useGraphQLDocument(query, variables, routeContext, operationContext);

  const { loading, error, errors, data, combinedVariables, QueryComponent } = graphQuery;

  if (error) {
    console.error('RenderQuery error:', error);
    return (
      <DataLayoutSpacing>
        <div>
          <QueryError error={error} />
        </div>
      </DataLayoutSpacing>
    );
  }

  if (errors && errors.length > 0 && !data) {
    return (
      <DataLayoutSpacing>
        <div className='sg-u-vs-1'>
          {errors.map((error, idx) => (
            <DangerAlert key={idx}>
              <GraphQLQueryError error={error} />
            </DangerAlert>
          ))}
        </div>
      </DataLayoutSpacing>
    );
  }

  const selector = (QueryComponent as any).selector;
  const selectedData = selector ? selector(data, combinedVariables) : data;

  return (
    <QueryComponent
      data={selectedData}
      errors={errors ?? []}
      path={[]}
      loading={loading}
      variables={combinedVariables}
    />
  );
};
