import React from 'react';
import type { FieldNode, GraphQLType, DocumentNode, GraphQLSchema } from 'graphql';
import { isObjectType, getNamedType, isNonNullType, isListType } from 'graphql';

import { useMessage } from '@tmapy/intl';
import { Icon, SIZE, SvgMail, SvgExternal, TertiaryLink } from '@tmapy/style-guide';
import type { RouteContext } from '@tmapy/types';

import { getDirectives } from '../../utils/getDirectives';
import { createSelectionSetComponent } from '../createSelectionSetComponent';
import type { DataComponent, DataProps } from '../../types';
import { nameComponent } from '../../utils/nameComponent';
import { filterErrors } from '../../utils/filterErrors';
import { msg } from '../../messages';

export const TABLE_ACTION_COLUMN_TYPES = ['Email', 'URL'];

export const ActionComponentMap: Record<string, DataComponent> = {
  Email: ({ data }) => {
    const formatMessage = useMessage();
    return (
      <TertiaryLink
        href={data ? `mailto:${data}` : ''}
        tooltip={formatMessage(msg.sendEmail, { email: data })}
        isDisabled={!data}
        icon={{
          element: <Icon element={<SvgMail />} size={SIZE.MED} tooltip={data} />,
        }}
      />
    );
  },
  URL: ({ data }) => {
    const formatMessage = useMessage();
    return (
      <TertiaryLink
        href={data}
        tooltip={formatMessage(msg.openUrl, { url: data })}
        isDisabled={!data}
        target='_blank'
        icon={{
          element: <Icon element={<SvgExternal />} size={SIZE.MED} tooltip={data} />,
        }}
      />
    );
  },
};

export function createInlineActionComponent(
  graphqlType: GraphQLType,
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
  routeContext: RouteContext,
  intlPrefix: string | null,
): DataComponent {
  const namedType = getNamedType(graphqlType);
  const typeName = namedType.name;

  let Component = ActionComponentMap[typeName];

  const directives = getDirectives(field.directives);
  const urlDirective = directives.url;
  if (urlDirective) {
    if (!['String', 'URL'].includes(typeName)) {
      throw new Error(`Directive @url can be used only on String field, ${typeName} field given`);
    }

    let transformHref: (url: string, parentContext?: any) => string = (url: string) => url;
    const { replaceHashParams } = urlDirective;
    if (replaceHashParams) {
      transformHref = (urlString: string, parentContext: any) => {
        const url = new URL(urlString, window.location.href);

        const hashParams = new URLSearchParams(url.hash.slice(1));
        for (const [key, value] of Object.entries(replaceHashParams)) {
          value === null
            ? hashParams.delete(key)
            : hashParams.set(key, parentContext?.[value as string]);
        }
        url.hash = hashParams.toString();
        return url.toString();
      };
    }

    Component = nameComponent(`Directive.url`, (props: DataProps) => {
      const href = transformHref(props.data, props.parentContext);
      return <ActionComponentMap.URL {...props} data={href} />;
    });
  }

  if (isObjectType(namedType)) {
    if (!field.selectionSet?.selections) {
      throw new Error('Field without selectionSet in CreateInlineActionComponent.');
    }

    Component = createSelectionSetComponent(
      field.selectionSet.selections,
      namedType.getFields(),
      document,
      schema,
      routeContext,
      intlPrefix,
    );
  }

  if (!Component) {
    Component = () => <>Neznamy datovy typ {typeName}</>;
  }

  let unwrapNonNull = graphqlType;
  if (isNonNullType(unwrapNonNull)) {
    unwrapNonNull = unwrapNonNull.ofType;
  }

  if (isListType(unwrapNonNull)) {
    return nameComponent(
      `InlineActionList`,
      ({ data, errors, path, variables, loading }: DataProps) => (
        <>
          {(data as any[])?.map((item, idx) => {
            const subPath = [...path, idx];
            return (
              <React.Fragment key={idx}>
                <Component
                  data={item}
                  errors={filterErrors(errors, subPath)}
                  path={subPath}
                  variables={variables}
                  loading={loading}
                />{' '}
              </React.Fragment>
            );
          })}
        </>
      ),
    );
  }

  return Component;
}
