import { getNamedType, isEnumType } from 'graphql';

import { useLink, useLocation } from '@tmapy/router';
import { useMessage } from '@tmapy/intl';
import { IndeterminedTick } from '@tmapy/style-guide';

import { SORTING_METHOD } from '../../constants';
import type { PaginationVariableNames, Variables } from '../../types';
import type { DirectiveMap } from '../../utils/getDirectives';
import { getDirectives } from '../../utils/getDirectives';
import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { nameComponent } from '../../utils/nameComponent';

import type { Selection } from '../../components/TableView';
import { ActionDirective } from '../../components/ActionDirective';
import { TableHeadCell } from '../../components/TableHeadCell';

import { isBadgeType } from '../inputComponents/createBadgeComponent';

import type { UseActionDirective } from './createUseActionDirective';
import type { Column } from './createTableComponent';

const createTableHeaderColumnComponent = (
  column: Column,
  intlPrefix: string,
  orderVariable: string,
  {
    beforeVariableName,
    afterVariableName,
    firstVariableName,
    lastVariableName,
  }: PaginationVariableNames,
) => {
  const columnId = getFieldAliasOrName(column.fieldNode);
  const directives = getDirectives(column.fieldNode.directives);

  const namedType = getNamedType(column.graphQLField.type);
  const isBadge = isEnumType(namedType) && isBadgeType(namedType);

  return nameComponent(`HeaderColumn`, () => {
    const formatMessage = useMessage();
    const currentLocation = useLocation();
    const { params } = currentLocation;
    const order = params[orderVariable];

    const orderColumnName: string = directives.order?.name ?? columnId;

    const resetOrderParams = {
      [beforeVariableName]: undefined,
      [afterVariableName]: undefined,
      [firstVariableName]: params[firstVariableName] ?? params[lastVariableName],
      [lastVariableName]: undefined,
    };

    const handleAscBtnClick = useLink(currentLocation.route?.id, {
      ...params,
      ...resetOrderParams,
      [orderVariable]: orderColumnName,
    });

    const handleDescBtnClick = useLink(currentLocation.route?.id, {
      ...params,
      ...resetOrderParams,
      [orderVariable]: `-${orderColumnName}`,
    });

    const handleCancelOrderBtnClick = useLink(currentLocation.route?.id, {
      ...params,
      ...resetOrderParams,
      [orderVariable]: undefined,
    });

    let sortingMethod: SORTING_METHOD | undefined = directives.order
      ? SORTING_METHOD.UNSORTED
      : undefined;
    let handleClick = directives.order ? handleAscBtnClick : undefined;
    if (order === orderColumnName) {
      sortingMethod = SORTING_METHOD.ASC;
      handleClick = handleDescBtnClick;
    } else if (order === `-${orderColumnName}`) {
      sortingMethod = SORTING_METHOD.DESC;
      handleClick = handleCancelOrderBtnClick;
    }

    return (
      <TableHeadCell
        columnId={columnId}
        sortingMethod={sortingMethod}
        isBadge={isBadge}
        {...handleClick}
      >
        {formatMessage.fallback([`${intlPrefix}.${columnId}`, columnId]) ?? columnId}
      </TableHeadCell>
    );
  });
};

type TableHeaderProps = {
  variables: Variables;
  selection: Selection;
  isLoading: boolean;
  onChangeAllSelection(value: boolean | null): void;
};

export const createTableHeaderComponent = (
  columnsInTable: Column[],
  actionColumns: Column[],
  rowDirectives: DirectiveMap,
  intlPrefix: string,
  orderVariable: string,
  paginationVariables: PaginationVariableNames,
  useActionDirectives: UseActionDirective[],
  idName: string,
  hasMassSelection: boolean,
) => {
  const loadFeatureDirective = rowDirectives?.loadFeature;
  const loadFeatureDirectiveQueryName = loadFeatureDirective?.query;

  const ewktColumn = actionColumns.find((column) => {
    const directives = getDirectives(column.fieldNode.directives);
    return directives.ewkt;
  });

  actionColumns = actionColumns.filter((column) => column !== ewktColumn);

  const columns = columnsInTable.map((column) => {
    const columnId = getFieldAliasOrName(column.fieldNode);
    const HeaderColumn = createTableHeaderColumnComponent(
      column,
      intlPrefix,
      orderVariable,
      paginationVariables,
    );
    return <HeaderColumn key={columnId} />;
  });

  return nameComponent(`ColumnsHeader`, (props: TableHeaderProps) => {
    let ids = undefined;
    switch (props.selection.all) {
      case null:
        ids = props.selection.ids;
        break;
      case true:
        ids = props.selection.allIds;
        break;
    }

    const rowActionDirective = useActionDirectives.map((useActionDirective) => {
      return useActionDirective({
        variables: props.variables,
        data: { [idName]: ids },
        confirmAction: true,
      });
    });

    const isChecked = !!props.selection.all || props.selection.all;

    const handleChangeAllSelection = () => {
      let newValue;
      switch (isChecked) {
        case true:
          newValue = false;
          break;
        case null:
          newValue = true;
          break;
        case false:
          newValue = null;
          break;
      }
      props.onChangeAllSelection(newValue);
    };

    const isSomeLoading =
      props.isLoading || rowActionDirective.some(([actionStatus]) => actionStatus.loading);

    if (columns.length === 0) {
      return null;
    }
    return (
      <thead>
        <tr>
          {hasMassSelection && (
            <th className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1 sg-a-ai-b '>
              <IndeterminedTick isChecked={isChecked} onChange={handleChangeAllSelection} />
            </th>
          )}
          {columns}
          {actionColumns.map((_, index) => (
            <th className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1/2' key={index} />
          ))}
          {(ewktColumn || loadFeatureDirectiveQueryName) && (
            <th className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1/2' />
          )}
          {rowActionDirective.map(([actionStatus, buttonProps], index) => (
            <th className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1/2' key={index}>
              {buttonProps.isMassAction && (
                <ActionDirective
                  error={actionStatus.error}
                  buttonProps={buttonProps}
                  isDisabled={isSomeLoading || props.selection.all === false}
                  isInHeader
                />
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  });
};
