import type {
  DirectiveNode,
  DocumentNode,
  GraphQLSchema,
  OperationDefinitionNode,
  SelectionNode,
} from 'graphql';
import { visit, visitWithTypeInfo, TypeInfo } from 'graphql';

import type { DirectiveArguments } from '../documentFactories/nodeFactories/createDirectiveNode';
import {
  addDirective,
  createDirectiveNode,
} from '../documentFactories/nodeFactories/createDirectiveNode';
import type { ComponentContext } from '../utils/getDirectives';
import { getDirectives } from '../utils/getDirectives';
import { isConnectionType } from '../utils/getConnectionInfo';

export type DecorationContext = {
  isPage?: boolean;
  isMassSelect?: boolean;
};

export const createDecorateDirectiveNode = (
  componentName: string,
  props?: Record<string, string | boolean | undefined>,
): DirectiveNode => {
  const directiveArguments: DirectiveArguments = [{ name: 'component', value: componentName }];
  if (props) {
    directiveArguments.push({ name: 'props', value: props });
  }
  return createDirectiveNode('decorate', directiveArguments);
};

export const decorateDocument = (
  schema: GraphQLSchema,
  documentAST: DocumentNode,
  decorationContext: DecorationContext,
): DocumentNode => {
  const typeInfo = new TypeInfo(schema);

  let isDetail = false;
  let firstFields: readonly SelectionNode[] = [];

  return visit(
    documentAST,
    visitWithTypeInfo(typeInfo, {
      OperationDefinition: {
        enter: (operationDefinition: OperationDefinitionNode) => {
          firstFields = operationDefinition.selectionSet.selections;
          return operationDefinition;
        },
        leave: (operationDefinition: OperationDefinitionNode) => {
          firstFields = [];
          return operationDefinition;
        },
      },
      Field: {
        enter(field) {
          const fieldType = typeInfo.getType();

          if (firstFields.includes(field) && fieldType) {
            isDetail = !isConnectionType(fieldType);
          }

          const isConnection = fieldType ? isConnectionType(fieldType) : false;

          const directives = getDirectives(field.directives);
          if (directives.decorate) {
            return {
              ...field,
              directives: field.directives?.filter(
                (directive) => directive.name.value !== 'decorate',
              ),
            };
          }

          if (!isConnection) return;
        },
        leave(field) {
          const fieldType = typeInfo.getType();
          const isConnection = fieldType ? isConnectionType(fieldType) : false;
          if (!isConnection) return;
          let tableContext: ComponentContext;
          if (decorationContext.isMassSelect) {
            tableContext = 'MASS_SELECT';
          } else if (isDetail) {
            tableContext = 'DETAIL';
          } else if (decorationContext.isPage) {
            tableContext = 'PAGE';
          }
          return addDirective(
            field,
            createDecorateDirectiveNode('Table', {
              context: tableContext,
            }),
          );
        },
      },
    }),
  );
};
