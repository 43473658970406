import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';

import type { RouteStateTWC, SplitLayoutDefaultSize, RouteComponentType } from '@tmapy/types';
import { useDispatch } from '@tmapy/redux';
import type { RouterState } from '@tmapy/router';
import { useLocation } from '@tmapy/router';
import { actionLoadNote, NOTE_HASH_PARAM_NAME } from '@tmapy/notes';

import type { ExtendedApolloClient } from 'lib/graphql';
import { RenderQuery, QueryError, SplitLayout, DataLayoutSpacing } from 'lib/graphql';
import { MapContainer, MapInfo, Panorama, PanoramaImperatives } from 'lib/map';

import { AccessDenied } from './errors/AccessDenied';
import { NotFoundPage } from './errors/NotFoundPage';
import { ErrorBoundary } from './errors/ErrorBoundary';

import { AboutPage } from './AboutPage';
import { Layout } from './Layout';

declare module '@tmapy/router' {
  interface ResolvedRouteConfig {
    query?: string;
    component?: RouteComponent;
    defaultSize?: SplitLayoutDefaultSize;
  }
}

type RouteProps = {
  route: RouteStateTWC;
  location: RouterState;
};

type RouteComponent =
  | React.FC<RouteProps>
  | React.ForwardRefExoticComponent<RouteProps & React.RefAttributes<PanoramaImperatives>>;

const asyncLoadRouteComponent: RouteComponent = ({ route, location }: RouteProps) => {
  const [modules, setModules] = useState<Partial<Record<RouteComponentType, RouteComponent>>>({});

  const LoadingRoute = () => null;

  if (!modules[route.component]) {
    setModules({ ...modules, [route.component]: LoadingRoute });

    let asyncLoading;
    switch (route.component) {
      case 'UserProfile':
        asyncLoading = import(/* webpackChunkName: "user-profile" */ '../lib/user-profile');
        break;

      case 'IDE':
        asyncLoading = import(/* webpackChunkName: "ide" */ '../lib/graphql-ide');
        break;

      default:
        throw new Error(`Loading asynchronous module '${route.component}' does not work.`);
    }

    if (asyncLoading) {
      asyncLoading
        .then((module) => {
          let RouteComponent: RouteComponent | undefined;
          if (typeof module === 'object' && Object.hasOwn(module ?? {}, 'RouteComponent')) {
            RouteComponent = (module as any).RouteComponent;
          } else if (typeof module === 'object' && Object.hasOwn(module ?? {}, 'default')) {
            RouteComponent = (module as any).default;
          }
          if (!RouteComponent) {
            throw new Error();
          }
          setModules({ ...modules, [route.component]: RouteComponent });
        })
        .catch(() => {
          setModules({ ...modules, [route.component]: NotFoundPage });
          throw new Error(`Loading asynchronous module '${route.component}' does not work.`);
        });
    }
  }

  const RouteComponent = modules[route.component] ?? LoadingRoute;

  return <RouteComponent route={route} location={location} />;
};

const RouterQuery: React.FC<RouteProps> = ({ location, route }) => {
  return (
    <ErrorBoundary>
      <RenderQuery
        key={route.id}
        query={route.query ?? ''}
        variables={location.params}
        routeContext={route.context}
        isPage
      />
    </ErrorBoundary>
  );
};

const componentMap: Partial<Record<RouteComponentType, RouteComponent>> = {
  RouterQuery,
  Map: MapContainer,
  MapInfo: MapInfo,
  About: AboutPage,
  Panorama: Panorama,
  NotFound: NotFoundPage,
};

export const Router: React.FC = () => {
  const dispatch = useDispatch();
  const client = useApolloClient() as ExtendedApolloClient;
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const route = location.route as RouteStateTWC | null;
  const noteId = location.hashParams[NOTE_HASH_PARAM_NAME];
  const componentRef = useRef<PanoramaImperatives>(null);

  const handleLayoutChange = () => {
    componentRef.current?.resize();
  };

  useEffect(() => {
    if (noteId) {
      dispatch(actionLoadNote(client, noteId));
    }
  }, [client, dispatch, noteId]);

  const routeComponent = route?.component ?? (route?.query ? 'RouterQuery' : 'NotFound');
  const Component = componentMap[routeComponent] ?? asyncLoadRouteComponent;

  const accessDenied = (route?.auth && !auth.isLoggedIn) || !route?.hasBasePermission;
  const isMap = route?.component === 'Map';

  return (
    <Layout>
      <SplitLayout
        routeId={route?.id}
        show={accessDenied ? 'data' : isMap ? 'map' : route?.showMap ? 'both' : 'data'}
        defaultSize={route?.defaultSize ?? 'medium'}
        onLayoutChange={handleLayoutChange}
      >
        {!route || routeComponent === 'NotFound' ? (
          <NotFoundPage />
        ) : client.error && Component === componentMap['RouterQuery'] ? (
          <DataLayoutSpacing>
            <div className='sg-u-vs-1'>
              <QueryError error={client.error} />
            </div>
          </DataLayoutSpacing>
        ) : accessDenied ? (
          <AccessDenied isLoggedIn={auth.isLoggedIn} />
        ) : !isMap ? (
          <Component
            ref={route?.component === 'Panorama' ? componentRef : undefined}
            location={location}
            route={route}
          />
        ) : null}
      </SplitLayout>
    </Layout>
  );
};
