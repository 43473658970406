import type { GraphQLSchema } from 'graphql';

import type { RouteComponentType } from '@tmapy/types';
import type { extendedTWC, LayerTreeChildrenConfig } from '@tmapy/config';
import { isLayerTreeGroupConfig, NavItem } from '@tmapy/config';
import { LocaleMessages, getCurrentLocale } from '@tmapy/intl';
import { AuthState } from '@tmapy/auth';

import type { _AppState } from '../app/reducer';
import type { HeaderToolProps } from '../app/HeaderTool';
import { createHasPermission } from '../auth/hooks/usePermission';

import { getRootRoute } from './getRootRoute';
import { processRoutesConfig } from './processRoutesConfig';

export type HEADER_TOOL = 'About';

export const HEADER_TOOLS: RouteComponentType[] = ['About'];

export const deriveAppStateFromConfig = (
  config: extendedTWC,
  schema: GraphQLSchema,
  message: LocaleMessages,
  auth: AuthState,
): _AppState => {
  const lang = getCurrentLocale();

  const hasPermission = createHasPermission(auth.permissions);
  const routes = processRoutesConfig(config, schema, hasPermission, lang, message);

  const defaultRoute = getRootRoute(routes, lang) ?? routes[0];

  const mapRoute = routes.find((route) => route.component === 'Map');

  const isDrawGeometry = (item: LayerTreeChildrenConfig) =>
    isLayerTreeGroupConfig(item) ? item.children.some(isDrawGeometry) : !!item.drawGeometryType;

  const isEditToolsEnabled = !!config.layerSwitcher?.groups?.some(isDrawGeometry);

  const headerTools: HeaderToolProps[] = routes
    .filter((route) => route.component && HEADER_TOOLS.includes(route.component as HEADER_TOOL))
    .map((route) => ({
      to: route.id,
      icon: route.component! as HEADER_TOOL,
      type: route.component! as HEADER_TOOL,
    }));

  const queries = schema.getQueryType()?.getFields() ?? {};
  const mutations = schema.getMutationType()?.getFields() ?? {};
  const isSyncEnabled =
    !!config.sync && !!mutations['createCoreAppSync'] && !!queries['coreAppSyncs'];
  const isNotesEnabled = !!config.notes && !!mutations['createNote'] && !!queries['note'];

  const mapRouteId = mapRoute?.id;

  let menu: NavItem[] | null = routes
    .filter(
      (route) => !!route.menuIcon && (!route.auth || auth.isLoggedIn) && route.hasBasePermission,
    )
    .map((route) => ({ to: route.id, icon: route.menuIcon! }));

  if (menu && menu.length === 0) {
    menu = null;
  } else if (menu && mapRouteId) {
    menu.unshift({ to: mapRouteId, icon: 'map' });
  } else if (menu && menu.length === 1) {
    menu = null;
    console.error('The menu does not display only one menu item.');
  }

  return {
    appId: config.appId,
    basename: config.basename,
    graphql: config.graphql,
    isEditor: config.isEditor,
    packageId: config.packageId,
    menu,
    routes,
    useAccessToken: !!config.auth,
    featureRouteMappings: config.featureRouteMappings ?? [],
    otherLinks: config.otherLinks ?? [],
    homeLink: config.homeLink,
    headerTools,
    defaultRouteId: defaultRoute.id,
    mapRouteId: mapRoute?.id,
    popups: [],
    isSyncEnabled,
    isExportMapEnabled: !!config.exportMap,
    isMeasureEnabled: !!config.measure,
    isNotesEnabled,
    isEditToolsEnabled,
    isUserLocationEnabled: !!config.userLocation,
    filtersVisibility: {},
  };
};
