import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import type { DocumentNode, GraphQLSchema, OperationDefinitionNode } from 'graphql';
import { getNamedType, isEnumType, Kind } from 'graphql';
import { useDebouncedCallback } from 'use-debounce';
import { useApolloClient } from '@apollo/client';
import classNames from 'classnames';

import type { GeoJSONFeature, RouteContext } from '@tmapy/types';
import {
  EWKTToGeoJSONObject,
  actionSetExtent,
  actionSetCenter,
  actionSetZoom,
  actionSetLayerFeatures,
  geoJSONFeatureToGeometryCollection,
  useOLMap,
  fireHighlightInteraction,
} from '@tmapy/mapcore';
import { useMessage } from '@tmapy/intl';
import { useLink, useLocation } from '@tmapy/router';
import { SvgMap, Checkbox, DangerAlert, DangerBadge, TertiaryBtn } from '@tmapy/style-guide';
import { useDispatch, useSelector } from '@tmapy/redux';
import { LAYER_IDS } from '@tmapy/config';
import { identity } from '@tmapy/utils';

import type { DataProps } from '../../types';
import type { DirectiveMap } from '../../utils/getDirectives';
import { getDirectives } from '../../utils/getDirectives';
import { getDirectivesFromDescription } from '../../utils/getDirectivesFromDescription';
import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { findQuery } from '../../utils/filters';
import { filterErrors, ownErrors } from '../../utils/filterErrors';
import { nameComponent } from '../../utils/nameComponent';
import { msg } from '../../messages';

import type { Selection } from '../../components/TableView';
import { ActionDirective } from '../../components/ActionDirective';
import { GraphQLQueryError } from '../../components/QueryError';

import { createInlineViewComponent } from '../inputComponents/createInlineViewComponent';
import { isBadgeType } from '../inputComponents/createBadgeComponent';

import { createInlineActionComponent } from './createInlineActionComponent';
import type { UseActionDirective } from './createUseActionDirective';
import type { Column } from './createTableComponent';

const createGeoJSONFeaturesFromColumn = (
  data: Record<string, any>,
  columnName: string,
  projection: any,
): GeoJSONFeature[] => {
  try {
    const { [columnName]: geom, ...properties } = data;
    if (!geom) return [];

    const feature: GeoJSONFeature = {
      id: properties.id,
      type: 'Feature',
      properties,
      geometry: EWKTToGeoJSONObject(geom, projection).geometry,
      crs: {
        type: 'name',
        properties: {
          name: projection,
        },
      },
    };
    return [feature];
  } catch (err) {
    console.error(err, data);
    return [];
  }
};

const createGeoJSONFeaturesFromQueryData = (
  data: Record<string, Record<string, any>>,
  loadFeatureDirectiveQuery: OperationDefinitionNode,
  projection: any,
): GeoJSONFeature[] => {
  try {
    if (data.featureList) {
      return data.featureList.features.edges
        .map(({ node }: { node: Record<string, any> }) => {
          if (!node) return null;
          const { geom, ...properties } = node;
          if (!geom) return null;
          const feature: GeoJSONFeature = {
            id: properties.id,
            type: 'Feature',
            properties,
            geometry: EWKTToGeoJSONObject(geom, projection).geometry,
            crs: {
              type: 'name',
              properties: {
                name: projection,
              },
            },
          };
          return feature;
        })
        .filter(identity);
    } else if (data.feature) {
      const { geom, ...properties } = data.feature;
      if (!geom) return [];
      const feature: GeoJSONFeature = {
        id: properties.id,
        type: 'Feature',
        properties,
        geometry: EWKTToGeoJSONObject(geom, projection).geometry,
        crs: {
          type: 'name',
          properties: {
            name: projection,
          },
        },
      };
      return [feature];
    }
    return [];
  } catch (err) {
    console.error(err, data);
    return [];
  }
};

type TableRowProps = DataProps & {
  selection: Selection;
  onSelectionChange(data: string): void;
};

export const createTableRowComponent = (
  dataFields: Column[],
  actionFields: Column[],
  rowDirectives: DirectiveMap,
  document: DocumentNode,
  schema: GraphQLSchema,
  routeContext: RouteContext,
  intlPrefix: string,
  useActionDirectives: UseActionDirective[],
  idName: string,
  hasMassSelection: boolean,
) => {
  const ColumnComponents = dataFields.map((column) => {
    const description = column.graphQLField.description;
    const directivesFromSchema = getDirectivesFromDescription(description);
    const ColumnComponent = createInlineViewComponent(
      column.graphQLField,
      directivesFromSchema,
      column.fieldNode,
      document,
      schema,
      routeContext,
      intlPrefix,
    );

    const namedType = getNamedType(column.graphQLField.type);
    const isBadge = isEnumType(namedType) && isBadgeType(namedType);

    return (props: DataProps) => (
      <td className={classNames({ 'sg-table--narrowCell sg-a-p-1 sg-a-ta-c': isBadge })}>
        <ColumnComponent {...props} />
      </td>
    );
  });

  const detailDirective = rowDirectives?.detail;
  const loadFeatureDirective = rowDirectives?.loadFeature;
  const loadFeatureDirectiveQueryName = loadFeatureDirective?.query;
  let loadFeatureDirectiveQuery: OperationDefinitionNode | undefined;

  if (loadFeatureDirectiveQueryName) {
    loadFeatureDirectiveQuery = findQuery(document, loadFeatureDirectiveQueryName);
  }

  const ewktColumn = actionFields.find((column) => {
    const directives = getDirectives(column.fieldNode.directives);
    return directives.ewkt;
  });

  actionFields = actionFields.filter((column) => column !== ewktColumn);

  const ActionComponents = actionFields.map((column) => {
    return createInlineActionComponent(
      column.graphQLField.type,
      column.fieldNode,
      document,
      schema,
      routeContext,
      intlPrefix,
    );
  });

  return nameComponent(`TableRow`, (props: TableRowProps) => {
    const formatMessage = useMessage();
    const apolloClient = useApolloClient();
    const location = useLocation();
    const detailRoute = detailDirective?.route;
    const dispatch = useDispatch();
    const projection = useSelector((state) => state.mapCore.view.projection);
    const abortControllerRef = React.useRef<AbortController>();
    const isEditor = useSelector((state) => state.app.isEditor);
    const map = useOLMap();

    const rowActionDirective = useActionDirectives.map((useActionDirective) => {
      return useActionDirective({
        variables: props.variables,
        data: { [idName]: props.data[idName] },
      });
    });

    const detailLink = useLink(
      detailRoute,
      { ...location.params, ...props.data },
      undefined,
      'push',
      true,
    );

    const handleClick = detailRoute
      ? (e: React.MouseEvent) => {
          // Left mouse button click
          if (window.getSelection()?.toString() === '') {
            if (!(e.target as Element).closest('a')) {
              detailLink.onClick(e);
            }
          }
        }
      : undefined;

    const handleCheckboxClick = (e: React.MouseEvent) => {
      e.stopPropagation();
    };
    const handleCheckboxChange = (e: React.ChangeEvent, { data }: { data: string }) => {
      props.onSelectionChange(data);
    };

    const handleClearHighlightedFeature = useCallback(() => {
      abortControllerRef.current?.abort();
      if (!handleHighlightFeature.isPending()) {
        dispatch(actionSetLayerFeatures(LAYER_IDS.HIGHLIGHT, []));
      }
      handleHighlightFeature.cancel();
    }, [dispatch]);

    const dataId = props.data?.[idName];

    const loadFeatures = useCallback(async () => {
      if (ewktColumn) {
        return createGeoJSONFeaturesFromColumn(
          props.data,
          ewktColumn?.fieldNode.name.value,
          projection,
        );
      }

      if (!loadFeatureDirectiveQuery) return;

      const response = await apolloClient.query({
        query: {
          kind: Kind.DOCUMENT,
          definitions: [loadFeatureDirectiveQuery],
        },
        variables: {
          id: dataId,
        },
        context: {
          fetchOptions: {
            signal: abortControllerRef.current?.signal,
          },
        },
      });

      const features = createGeoJSONFeaturesFromQueryData(
        response.data,
        loadFeatureDirectiveQuery,
        projection,
      );

      return features;
    }, [apolloClient, dataId, projection]);

    const handleHighlightFeature = useDebouncedCallback(async () => {
      abortControllerRef.current = new window.AbortController();
      const features = await loadFeatures();
      if (features?.length) {
        // It's interresting, that OL will firstly pan,
        // then redraw layer with newly highlighted feature
        if (isEditor) {
          dispatch(actionSetLayerFeatures(LAYER_IDS.HIGHLIGHT, features));
        } else {
          // fire map event pointermove over position -> set mapboxstyles in Interaction Highlight
          fireHighlightInteraction(map, features[0]);
        }
      }
    }, 200);

    const handleZoomFeature = useCallback(
      async (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const features = await loadFeatures();

        if (!features?.length) return;

        const replaceHashParams = loadFeatureDirective?.replaceHashParams;
        if (replaceHashParams) {
          const hashParams = new URLSearchParams(window.location.hash.slice(1));
          for (const [key, value] of Object.entries(replaceHashParams)) {
            value === null
              ? hashParams.delete(key)
              : hashParams.set(key, props.data?.[value as string]);
          }
          window.location.hash = hashParams.toString();
        }

        if (
          features.length === 1 &&
          features[0].geometry.type === 'Point' &&
          loadFeatureDirective?.pointZoomLevel
        ) {
          const center = features[0].geometry.coordinates;
          dispatch(actionSetCenter(center));
          dispatch(actionSetZoom(loadFeatureDirective?.pointZoomLevel));
        } else {
          const geometryCollection = geoJSONFeatureToGeometryCollection(features);
          dispatch(actionSetExtent(geometryCollection.getExtent()));
        }
      },
      [loadFeatures, dispatch, props.data],
    );

    useEffect(() => {
      return () => {
        handleClearHighlightedFeature();
      };
    }, [handleClearHighlightedFeature]);

    const errors = props.errors.filter(ownErrors(props.path));
    if (errors.length) {
      return (
        <>
          {errors.map((error) => (
            <tr>
              <td>
                <DangerAlert>
                  <GraphQLQueryError error={error} />
                </DangerAlert>
              </td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <tr
        onClick={handleClick}
        onMouseEnter={ewktColumn || loadFeatureDirectiveQuery ? handleHighlightFeature : undefined}
        onMouseLeave={
          ewktColumn || loadFeatureDirectiveQuery ? handleClearHighlightedFeature : undefined
        }
      >
        {hasMassSelection && (
          <td className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1' onClick={handleCheckboxClick}>
            <Checkbox
              onClick={handleCheckboxClick}
              onChange={handleCheckboxChange}
              data={dataId}
              name='MassSelection'
              isDisabled={props.selection.all === 'ALL'}
              isChecked={
                !!props.selection.all ||
                (props.selection.all != false && props.selection.ids.includes(dataId))
              }
            />
          </td>
        )}
        {dataFields.map((column, index) => {
          const columnId = getFieldAliasOrName(column.fieldNode);
          const Component = ColumnComponents[index];
          const subPath = [...props.path, columnId];
          const errors = filterErrors(props.errors, subPath);
          if (errors.length) {
            return (
              <td key={columnId}>
                <DangerBadge>
                  <FormattedMessage {...msg.dataError} />
                </DangerBadge>
              </td>
            );
          }
          return (
            <Component
              key={columnId}
              {...props}
              data={props.data?.[columnId]}
              errors={errors}
              path={subPath}
              parentContext={props.data}
            />
          );
        })}
        {actionFields.map((column, index) => {
          const columnId = getFieldAliasOrName(column.fieldNode);
          const Component = ActionComponents[index];
          const subPath = [...props.path, index];
          return (
            <td className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1/2' key={index}>
              <Component
                {...props}
                data={props.data[columnId]}
                errors={filterErrors(props.errors, subPath)}
                path={subPath}
                parentContext={props.data}
              />
            </td>
          );
        })}
        {(ewktColumn || loadFeatureDirectiveQuery) && (
          <td className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1/2'>
            {(!ewktColumn || props.data?.[ewktColumn.fieldNode.name.value]) && (
              <TertiaryBtn
                icon={{ element: <SvgMap /> }}
                tooltip={formatMessage(msg.showInMap)}
                onClick={handleZoomFeature}
              />
            )}
          </td>
        )}
        {rowActionDirective.map(([actionStatus, buttonProps], index) => (
          <td className='sg-table--narrowCell sg-a-ws-nw sg-a-p-1/2' key={index}>
            <ActionDirective
              error={actionStatus.error}
              buttonProps={buttonProps}
              isDisabled={props.loading || props.selection.all === 'ALL'}
            />
          </td>
        ))}
      </tr>
    );
  });
};
