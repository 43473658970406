import React from 'react';
import type { FieldNode, DocumentNode, GraphQLSchema, DirectiveNode, GraphQLField } from 'graphql';
import {
  getNamedType,
  isCompositeType,
  isEnumType,
  isListType,
  isNonNullType,
  isObjectType,
} from 'graphql';

import { useLink } from '@tmapy/router';

import { Link, LINK_SEVERITY } from '@tmapy/style-guide';
import type { RouteContext } from '@tmapy/types';

import type { DataComponent, DataProps } from '../../types';
import { getDirectives } from '../../utils/getDirectives';
import { nameComponent } from '../../utils/nameComponent';
import { filterErrors } from '../../utils/filterErrors';

import { createSelectionSetComponent } from '../createSelectionSetComponent';

import { InlineViewComponentMap } from './InlineViewComponentMap';
import { createBadgeComponent } from './createBadgeComponent';

type LinkToRouteProps = {
  routeId: string;
  data: any;
  children: React.ReactNode;
};

const LinkToRoute = ({ routeId, data, children }: LinkToRouteProps) => {
  const navigateToRoute = useLink(routeId, data, undefined, 'push', true);
  return (
    <Link {...navigateToRoute} severity={LINK_SEVERITY.COLORFUL}>
      {children}
    </Link>
  );
};

export function createInlineViewComponent(
  graphqlField: GraphQLField<any, any, any>,
  directivesFromSchema: readonly DirectiveNode[],
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
  routeContext: RouteContext,
  intlPrefix: string | null,
): DataComponent {
  const graphqlType = graphqlField.type;
  const namedType = getNamedType(graphqlType);
  const typeName = namedType.name;

  let Component = InlineViewComponentMap[typeName];

  const directives = getDirectives(field.directives);

  if (directives.text) {
    Component = directives.text.multiline
      ? InlineViewComponentMap.MultilineString
      : InlineViewComponentMap.String;
  }

  const urlDirective = directives.url;
  if (urlDirective || typeName === 'URL') {
    if (!['String', 'URL'].includes(typeName)) {
      throw new Error(`Directive @url can be used only on String field, ${typeName} field given`);
    }

    let transformHref: (url: string, parentContext: any) => string = (url: string) => url;
    const { replaceHashParams } = urlDirective ?? {};
    if (replaceHashParams) {
      console.debug('replaceHashParams', replaceHashParams);
      transformHref = (urlString: string, parentContext: any) => {
        const url = new URL(urlString, window.location.href);

        const hashParams = new URLSearchParams(url.hash.slice(1));
        for (const [key, value] of Object.entries(replaceHashParams)) {
          value === null
            ? hashParams.delete(key)
            : hashParams.set(key, parentContext?.[value as string]);
        }
        url.hash = hashParams.toString();
        return url.toString();
      };
    }

    Component = nameComponent(`Directive.url`, ({ data, parentContext }: DataProps) => {
      const href = transformHref(data, parentContext);
      return data ? (
        <Link href={href} target='_blank' severity={LINK_SEVERITY.COLORFUL} isExternal>
          {href}
        </Link>
      ) : null;
    });
  }

  if (!Component && isEnumType(namedType)) {
    Component = createBadgeComponent(namedType);
  }

  if (!Component && isObjectType(namedType)) {
    if (!field.selectionSet?.selections) {
      throw new Error('Field without selectionSet in CreateInlineViewComponent.');
    }

    Component = createSelectionSetComponent(
      field.selectionSet.selections,
      namedType.getFields(),
      document,
      schema,
      routeContext,
      intlPrefix,
      undefined,
      ' ',
    );
  }

  if (!Component) {
    Component = () => <>Neznamy datovy typ {typeName}</>;
  }

  if (directives.detail) {
    if (!isCompositeType(namedType)) {
      throw new Error(`Directive @detail can be used only on object field`);
    }

    const detailRoute = directives.detail.route;

    Component = nameComponent(`Directive.detail`, (props: DataProps) => {
      const InnerComponent = createInlineViewComponent(
        graphqlField,
        directivesFromSchema,
        { ...field, directives: [] },
        document,
        schema,
        routeContext,
        intlPrefix,
      );

      return props.data ? (
        <LinkToRoute routeId={detailRoute} data={props.data}>
          <InnerComponent {...props} />
        </LinkToRoute>
      ) : null;
    });
  }

  let unwrapNonNull = graphqlType;
  if (isNonNullType(unwrapNonNull)) {
    unwrapNonNull = unwrapNonNull.ofType;
  }

  if (isListType(unwrapNonNull)) {
    return nameComponent(
      `InlineViewList`,
      ({ data, errors, path, variables, loading }: DataProps) => (
        <>
          {(data as any[])?.map((item, idx) => {
            const subPath = [...path, idx];
            return (
              <React.Fragment key={idx}>
                <Component
                  data={item}
                  errors={filterErrors(errors, subPath)}
                  path={subPath}
                  variables={variables}
                  loading={loading}
                />{' '}
              </React.Fragment>
            );
          })}
        </>
      ),
    );
  }

  return Component;
}
